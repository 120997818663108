import { Admin as RAdmin, Resource } from 'react-admin';
import React from 'react';
import authProvider from './authProvider';
import company from './components/Company';
import user from './components/User';

function Admin({ provider }) {
  return (
    <RAdmin
      title={`Rent Safe Admin`}
      authProvider={authProvider}
      dataProvider={provider}
    >
      <Resource {...user} />
      <Resource {...company} />
    </RAdmin>
  );
}

export default Admin;
