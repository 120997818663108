import React from 'react';
import {
  List,
  Edit,
  Datagrid,
  TextField,
  SelectInput,
  SelectField,
  Create,
  SimpleForm,
  TextInput,
  EditButton,
} from 'react-admin';

const ModelList = props => (
  <List {...props}>
    <Datagrid rowClick='edit'>
      <TextField source='id' />
      <TextField source='email' />
      <SelectField
        source='role'
        choices={[
          { id: 'superadmin', name: 'SuperAdmin' },
          { id: 'rentsafe_admin', name: 'Admin' },
          { id: 'property_manager', name: 'Property Manager' },
          { id: 'user', name: 'User' },
        ]}
      />
      <EditButton />
    </Datagrid>
  </List>
);

const ModelCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source={`email`} options={{ label: 'Email' }} />
      <TextInput source={`firstName`} options={{ label: 'First Name' }} />
      <TextInput source={`lastName`} options={{ label: 'Last Name' }} />
      <TextInput source={`password`} options={{ label: 'Password' }} />
      <SelectInput
        source={`role`}
        choices={[
          { id: 'superadmin', name: 'SuperAdmin' },
          { id: 'rentsafe_admin', name: 'Admin' },
          { id: 'property_manager', name: 'Property Manager' },
          { id: 'user', name: 'User' },
        ]}
        options={{ label: 'Role' }}
      />
    </SimpleForm>
  </Create>
);

const ModelEdit = props => (
  <Edit undoable={true} {...props}>
    <SimpleForm>
      <TextInput source={`email`} options={{ label: 'Email' }} />
      <TextInput source={`firstName`} options={{ label: 'First Name' }} />
      <TextInput source={`lastName`} options={{ label: 'Last Name' }} />
      <SelectInput
        source={`role`}
        choices={[
          { id: 'superadmin', name: 'SuperAdmin' },
          { id: 'rentsafe_admin', name: 'Admin' },
          { id: 'property_manager', name: 'Property Manager' },
          { id: 'user', name: 'User' },
        ]}
        options={{ label: 'Role' }}
      />
    </SimpleForm>
  </Edit>
);

export default {
  name: 'AdminUser',
  options: {
    label: 'User',
  },
  list: ModelList,
  create: ModelCreate,
  edit: ModelEdit,
};
